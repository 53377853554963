<template>
  <div class="relative">
    <client-only>
      <div
        v-if="!isMobile"
        class="grid grid-cols-2 gap-6"
        :class="[
          { '!grid-rows-2 !gap-3': gallerySliced.length > 2 },
          { '!grid-cols-[_1fr,_20%_20%]': !showFullSize && gallerySliced.length > 2 },
          { '!grid-cols-[_1fr,_1fr_20%_20%]': showFullSize },
        ]"
      >
        <img
          v-for="(image, index) in gallerySliced"
          :key="index"
          :src="image"
          :alt="`preview-${index}`"
          class="h-[370px] w-full rounded-2xl object-cover"
          :class="[
            { '!h-[179px] first:row-span-2 first:!h-[370px]': gallerySliced.length > 2 },
            { '[&:nth-child(2)]:row-span-2 [&:nth-child(2)]:!h-[370px]': showFullSize },
          ]"
        />
      </div>
      <div v-else class="relative">
        <Swiper
          loop
          :slides-per-view="1.1"
          space-between="8px"
          :centered-slides="true"
          :modules="[SwiperNavigation, SwiperPagination]"
          class="h-auto !overflow-y-visible"
        >
          <SwiperSlide v-for="(image, index) in gallery" :key="index" class="h-auto cursor-pointer select-none">
            <img :src="image" :alt="`preview-${index}`" class="max-h-[200px] w-full rounded-xl object-cover" />
          </SwiperSlide>
        </Swiper>
      </div>
      <UiButtonBase
        id="view-photos"
        type="secondary"
        :class="
          isMobile
            ? 'mx-auto mt-2 !w-[calc(100%-32px)]'
            : 'absolute bottom-4 right-4 z-10 rounded-xl bg-white px-4 py-2'
        "
        @click="showFullView()"
      >
        View all photo ({{ gallery.length }})
      </UiButtonBase>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { POPUPS } from '@/components/dynamic/maps'
import { useUiStore } from '@/store/ui'
import type { PropertyTypes } from '@/types'

const uiStore = useUiStore()

type Props = {
  item: PropertyTypes.PropertyDetailed
  fullSize?: boolean
}

const { smaller, greater } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')
const isLargeScreen = greater('2xl')

const props = defineProps<Props>()

const picturesResolutions = computed(() => {
  if (isMobile.value) return [650, 600]
  if (isLargeScreen.value) return [1920, 1600]
  return [1280, 1100]
})

const gallery = computed(() => {
  const additionalPhotos = props.item.albums.flatMap((item: PropertyTypes.ImageAlbum) => item.images)
  return [...props.item.album, ...additionalPhotos].map(
    (item: string) =>
      `https://cdn-img.thrivestate.dev/VC_qlYD_wXOjkaoVM_h2NTa_Ccg_GdLh0swrfoVlRWs/rs:fit:${
        picturesResolutions.value[0]
      }:${picturesResolutions.value[1]}:0/g:no/${btoa(
        item.replace('https://cdn.thrivestate.dev/', 'gs://thrivestate-dev-bucket-europe-west1/')
      )}.webp`
  )
})

const showFullSize = computed(() => props.fullSize && gallery.value.length > 5)

const gallerySliced = computed(() => {
  if (showFullSize.value) return gallery.value.slice(0, 6)
  if (!props.fullSize && gallery.value.length >= 5) return gallery.value.slice(0, 5)
  return gallery.value.slice(0, 2)
})

const showFullView = () => {
  uiStore.showSidePanel(POPUPS.PROPERTY_FULL_PHOTOS, { item: props.item, gallery: gallery.value })
}
</script>

<style scoped></style>
